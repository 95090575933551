import React from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { LanguageSwitch } from '../RefLightForm/LanguageSwitch';

const useStyles = makeStyles(() => ({
    row: {
      height: 145
    }
  }));

export const FormHeader = () => {
    const classes = useStyles();

    return (
        <Grid container style={{ padding: '0 24px 24px 24px' }}>
                <Grid
                    container
                    item
                    direction="column"
                    xs={9}
                >
                    <Grid
                        item
                        container
                        xs
                        className={classes.row}
                        direction="column"
                        align="left"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <h1>RefLight - Reference Letter Generator</h1>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction="column"
                    xs={3}
                >
                    <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        justify="flex-end"
                        className={classes.row}
                    >
                        <LanguageSwitch />
                    </Grid>
                </Grid>
            </Grid>
    )
}
