import React, { useState } from 'react';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

const StyledToggleButton = withStyles({
    root: {
        backgroundColor: '',
        color: '#9E9E9E',
        '&$selected': {
            backgroundColor: '#3f51b5',
            color: 'white',
            '&:hover': {
                backgroundColor: '#3f51b5',
                color: 'white',
            }
        }
    },
    selected: {}
})(ToggleButton);

export const LanguageSwitch = () =>  {
    const [t, i18n] = useTranslation('general');
    const [isLeftToggleButtonSelected, setIsLeftToggleButtonSelected] = useState(true);
    const [isRightToggleButtonSelected, setIsRightToggleButtonSelected] = useState(false);

    const hanldeLanguageSelection = (_, newSelectedLanguage) => {
        i18n.changeLanguage(newSelectedLanguage);
        setIsLeftToggleButtonSelected(newSelectedLanguage === 'en');
        setIsRightToggleButtonSelected(newSelectedLanguage === 'de');
    };

    return (
        <div>
            <h4>{t('general:language_selection_hint')}:</h4>
            <ToggleButtonGroup
                size="small"
                value={t}
                exclusive
                onChange={hanldeLanguageSelection}
            >
                <StyledToggleButton selected={isLeftToggleButtonSelected} value="en">English</StyledToggleButton>
                <StyledToggleButton selected={isRightToggleButtonSelected} value="de">Deutsch</StyledToggleButton>
            </ToggleButtonGroup>
        </div>
      );
}
