import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export const Grading = ({
    name,
    activeGrade,
    grades,
    gradeChanged
}) =>  {
  const [t] = useTranslation('grading');

    return (
        <ToggleButtonGroup
          name={name}
          value={activeGrade}
          exclusive
          onChange={gradeChanged}
          aria-label="Grade"
        >
        {
          grades.map((grade, index) => (<ToggleButton value={index}>{t('grading:grades.'+grade)}</ToggleButton>))
        }
        </ToggleButtonGroup>
      );
}
