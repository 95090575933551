import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Grading } from './Grading';
import { grades } from '../FormData';
import { useTranslation } from 'react-i18next';

export const Grades = ({
 gradings,
 changed
}) => {
    const [t] = useTranslation('grading');

    return (
        <>
            <h4>{t('grading:hint')}</h4>
            {
                Object.keys(gradings).map((name, index) => {
                    const gradingInfo = gradings[name];
                    return (
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">{t('grading:categories.'+name)}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Grading
                                    name={gradingInfo.name}
                                    activeGrade={gradingInfo.activeGrade}
                                    gradeChanged={(event, value) => {
                                        changed(
                                            { target: { name: gradingInfo.name, value: value} }
                                        )
                                    }}
                                    grades={grades}/>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </>
    );
};
