import { Grid, Button, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Activities = ({
    activities,
    inputChanged,
    activityRemoved,
    activityAdded,
    errors
}) => {
    const [t] = useTranslation(['activities', 'general']);

    return (
        <div>
            <h4>{t('activities:hint')}</h4>
            <h5>{t('activities:sub_hint')}</h5>
            <Grid container spacing={3}>
                {
                    activities.map((activity, index) =>
                        <>
                            <Grid item xs={8}>
                                <TextField
                                    value={activity}
                                    variant="filled"
                                    onChange={(event) => inputChanged(event, index)}
                                    multiline
                                    fullWidth={true}
                                    rows={5}
                                    error={errors.activities && !!errors.activities[index]}
                                    helperText={(errors.activities ? t('general:'+errors.activities[index]) : null)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => activityRemoved(index)}
                                    disabled={activities.length <= 1}
                                >
                                    {t('activities:delete_activity_button_title')}
                                </Button>
                            </Grid>
                        </>
                    )
                }
            </Grid>
            <Button
                variant="contained"
                color="primary"
                onClick={activityAdded}
                startIcon={<AddIcon />}
                style={{margin: '16px 0 16px 0'}}
            >{t('activities:add_activity_button_title')}</Button>
        </div>
    );
};
