import fileDownload from 'js-file-download';

export const generateRefLetter = (data) => {
    const HEADER_FILE_NAME = 'X-GeneratedFileName';

    const request = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(data)
    };

    let fileName = 'file.docx' // backup file name
    fetch('/api/generate', request)
        .then(res => {
            fileName = res.headers.get(HEADER_FILE_NAME);
            return res.blob();
        })
        .then(blob => fileDownload(blob, fileName));
}
