import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';

import { Login } from './components/Login/Login';
import { Logout } from './components/Logout/Logout';
import { AuthCallback } from './components/AuthCallback/AuthCallback';
import { RefLightForm } from './components/RefLightForm/RefLightForm';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';

import { appTheme } from './AppTheme';

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Container className="App" maxWidth="md">
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/logout" component={Logout} />
              <PrivateRoute path="/form" component={RefLightForm} />
              <Route exact path="/oauth-callback" component={AuthCallback} />
            </Switch>
          </Router>
        </Container>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
