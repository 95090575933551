import React, { useEffect } from 'react';

import { logout } from '../../services/auth-service';

export const Logout = () => {
    useEffect(() => {
        logout();
        window.location.replace('/');
    });

    return (
        <div>
        </div >
    );
};
