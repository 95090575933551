import React from 'react';
import { Table, TableBody, TableRow, TableCell, Container } from '@material-ui/core';
import { grades } from '../FormData';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const translateGender = (gender) => {
    return gender.toLowerCase() === 'female' ? 'female' : 'male';
}

const translateMentorFlag = (isMentor, t) => {
    return isMentor ? t('overview:isMentor') : t('overview:isNoMentor');
}

const convertDate = (timestamp) => {
    return moment(timestamp).format('DD.MM.YYYY');
}

const createData = (values, t) => {
    const data = {
        'full_name': `${values.firstName} ${values.familyName}`,
        'date_of_birth': convertDate(values.birthDate),
        'gender': t('overview:gender_'+translateGender(values.gender)),
        'position': values.positionDesignation,
        'time_of_employment': `${convertDate(values.startDate)} `+t('overview:to')+` ${convertDate(values.endDate)}`,
        'activities': values.activities.map(activity => (<p>{activity}</p>)),
        'grade_domain_knowledge': t('grading:grades.'+grades[values.domainKnowledge]) ,
        'grade_overall_performance': t('grading:grades.'+grades[values.overallPerformance]),
        'grade_over_picture': t('grading:grades.'+grades[values.overallPicture]),
        'partner': values.partner,
        'mentor': translateMentorFlag(values.isMentor, t)
    }
    if (values.isMentor) {
        data['Mentoring'] = t('grading:grades.'+grades[values.mentoring]);
    }
    return data;
}

export const Overview = ({
    values
}) => {
    const [t] = useTranslation(['overview', 'grading', 'employeeInformation']);
    const rows = createData(values, t);
    return (
        <Container>
            <h4>{t('overview:hint')}</h4>
            <Table>
                <TableBody>
                  {
                      Object.keys(rows).map((key, index) => (
                          <TableRow>
                              <TableCell>{t('overview:'+key)}</TableCell>
                                <TableCell>{rows[key]}</TableCell>
                          </TableRow>
                      ))
                  }
                </TableBody>
              </Table>
        </Container>
    )
};
