import React from 'react';
import Box from '@material-ui/core/Box';

import logo from '../../assets/images/NetlightLogo.svg';
import boidsImg from '../../assets/images/FlyingBoids.svg';
import { useStyles } from './Login.styles';
import { SlackLogin } from './SlackLogin/SlackLogin'
import { isLoggedIn } from '../../services/auth-service'
import { Redirect } from 'react-router-dom';


export const Login = (props) => {
    const classes = useStyles();

    return (
        isLoggedIn() ?
            <Redirect to={{ pathname: '/form', state: { from: props.location } }} />
            :
            <Box className={classes.Container}>
                <img className={classes.leftImage} alt="Flying boids" src={boidsImg} />
                <img alt="Netlight logo" src={logo} />
                <h1>Welcome to RefLight!</h1>
                <h2>Generate reference letters on the fly.</h2>
                <br />
                <SlackLogin />
            </Box>
    );
}
