import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    Container: {
        position: 'absolute',
        top: '48%', left: '50%', bottom: '0', right: '0',
        textAlign: 'center',
        transform: 'translate(-50%, -50%)',
        '& h2': {
            fontWeight: 'normal',
            fontSize: '30px',
            lineHeight: '30px',
        },
    },
});
