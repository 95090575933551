import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { partners } from '../FormData';
import { useTranslation } from 'react-i18next';

export const Partners = ({
    activePartner,
    changed
}) => {
    const [t] = useTranslation('partners');
    const menuItems = partners.map(partner => (
        <MenuItem value={`${partner.fullName}, ${partner.title}`}>{partner.fullName}</MenuItem>
    ));

    return (
        <div>
            <h4>{t('partners:hint')}</h4>
            <FormControl component="fieldset" variant="filled">
            <InputLabel>Partner</InputLabel>
                <Select
                    value={activePartner}
                    onChange={changed}
                    name="partner"
                    label="Partner"
                    autoWidth
                >
                {menuItems}
            </Select>
            </FormControl>
        </div>
    );
};
