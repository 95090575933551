import React from 'react';

export const SlackLogin = (props) => {
    const slackUserScope = "identity.basic";
    const slackClientID = "2172920488.1440026091075";
    const redirectUri = encodeURIComponent(`${window.location.origin}/oauth-callback`);
    const slackAuthLink = `https://slack.com/oauth/v2/authorize?user_scope=${slackUserScope}&client_id=${slackClientID}&redirect_uri=${redirectUri}`;
    const slackButtonSrc = "https://platform.slack-edge.com/img/sign_in_with_slack.png";
    const slackButtonSrc2x = "https://platform.slack-edge.com/img/sign_in_with_slack@2x.png";
    const slackButtonSrcSet = `${slackButtonSrc} 1x, ${slackButtonSrc2x} 2x`;

    return (
        <a href={slackAuthLink}>
            <img
                alt=' Sign in with Slack'
                height="40"
                width="172"
                src={slackButtonSrc}
                srcSet={slackButtonSrcSet} />
        </a>
    );
}
