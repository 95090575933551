import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    Container: {
        position: 'absolute',
        top: '48%', left: '50%', bottom: '0', right: '0',
        textAlign: 'center',
        transform: 'translate(-50%, -50%)',
        '& h1': {
            fontWeight: 'normal',
            fontSize: '42px',
            lineHeight: '42px',
        },
        '& h2': {
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '24px',
        },
    },
    leftImage: {
        position: 'absolute',
        margin: '50px 0 0 -290px',
    },
});
