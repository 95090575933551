import React from 'react';
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Grading } from './Grading';
import { grades } from '../FormData';
import { useTranslation } from 'react-i18next';

export const Mentoring = ({
    name,
    mentorLabel,
    isMentor,
    mentoring,
    changed,
    checkboxTicked,
}) => {
    const [t, i18next] = useTranslation('mentoring');
    const mentor = t(i18next.language === 'en' ? 'mentoring:mentor' : mentorLabel);
    const checkBoxText = t('mentoring:was_mentor_checkbox_text_prefix')+name+t('mentoring:was_mentor_checkbox_text_suffix')+mentor;

    return (
        <>
            <h4 style={{ marginTop: '40px' }}>{t('mentoring:hint_prefix')} {name} {mentor}? {t('mentoring:hint_suffix')}</h4>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isMentor}
                                onChange={checkboxTicked}
                            />
                        }
                        label={checkBoxText}
                        labelPlacement="end"
                    />
                </Grid>
                {isMentor ? (
                    <>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Mentoring</Typography>
                        </Grid>
                        <Grid item={9}>
                            <Grading
                                name="mentoring"
                                activeGrade={mentoring}
                                gradeChanged={changed}
                                grades={grades}
                            />
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </>
    );
};
