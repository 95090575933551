import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Refresh } from '@material-ui/icons';

import logo from '../../assets/images/NetlightLogo.svg';
import { fetchJWT } from '../../services/auth-service';
import { useStyles } from './AuthCallback.styles';


export const AuthCallback = () => {
    const classes = useStyles();
    const parsedParameters = queryString.parse(window.location.search);
    const authorizationCode = parsedParameters.code ? parsedParameters.code : '';
    const [error, setError] = useState('');

    useEffect(() => {
        const onError = (error) => setError(error.message || 'unknown');

        fetchJWT(authorizationCode.toString())
            .then(() => {
                console.log("Got JWT token");
                window.location.replace('/form');
            })
            .catch(err => {
                onError(err);
            });
    }, [authorizationCode]);

    return (
        <Box className={classes.Container}>
            <img alt="Netlight logo" src={logo} />
            {!error ?
                <>
                    <h2>Bear for a second. We are logging you in...</h2>
                    <br />
                    <CircularProgress size={80} />
                </>
                :
                <>
                    <h2>Oh no, we could not log you in with Slack!</h2>
                    <br />
                    <Button
                        className={classes.Button}
                        variant="contained"
                        href="/"
                        color="primary"
                        endIcon={<Refresh />}>
                        Try again
                    </Button>
                </>

            }
        </Box>
    );
};
