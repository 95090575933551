import React from 'react';
import { Grid, TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { terminationTypes } from '../FormData'
import { isEmpty } from '../../../helpers/object';
import { useTranslation } from 'react-i18next';

export const EmployeeInformation = ({
    firstName,
    familyName,
    gender,
    birthDate,
    dateChanged,
    position,
    startDate,
    endDate,
    terminationType,
    inputChanged,
    startDateChanged,
    endDateChanged,
    errors
}) => {
    const [t] = useTranslation(['employeeInformation', 'general']);

    const inputStyle = {
        width: '100%'
    }

    const headlineStyle = {
        marginTop: '40px'
    }

    const terminationRadioButtons = terminationTypes.map((type, i) =>
        <FormControlLabel key={i} value={type} control={<Radio />} label={t('employeeInformation:termination_type.'+type)} />
    );

    const translateError = (name) => {
        return isEmpty(errors) ? null : t('general:'+name);
    }

    return (
        <div>
            <h4>{t('employeeInformation:personal.hint')}</h4>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label={t('employeeInformation:personal.first_name_text_field_placeholder')}
                        name="firstName"
                        value={firstName}
                        variant="filled"
                        onChange={inputChanged}
                        error={!!errors.firstName}
                        helperText={translateError(errors.firstName)}
                        style={inputStyle}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('employeeInformation:personal.last_name_text_field_placeholder')}
                        name="familyName"
                        value={familyName}
                        variant="filled"
                        onChange={inputChanged}
                        error={!!errors.familyName}
                        helperText={translateError(errors.familyName)}
                        style={inputStyle}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        inputVariant="filled"
                        disableFuture
                        openTo="year"
                        format="DD.MM.YYYY"
                        label={t('employeeInformation:personal.date_of_birth_picker_placeholder')}
                        views={["year", "month", "date"]}
                        value={birthDate}
                        onChange={dateChanged}
                        error={!!errors.birthDate}
                        helperText={translateError(errors.birthDate)}
                        style={inputStyle}
                        required
                        autoOk={true}
                    />
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <RadioGroup row aria-label="Geschlecht" name="gender" value={gender} onChange={inputChanged}>
                        <FormControlLabel style={{ marginRight: '32px' }} value="female" control={<Radio />} label={t('employeeInformation:personal.female_selection_toggle_title')} />
                        <FormControlLabel value="male" control={<Radio />} label={t('employeeInformation:personal.male_selection_toggle_title')} />
                    </RadioGroup>
                </Grid>
            </Grid>
            <h4 style={headlineStyle}>{t('employeeInformation:work.hint')}</h4>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        label={t('employeeInformation:work.position_text_field_placeholder')}
                        name="positionDesignation"
                        value={position}
                        variant="filled"
                        onChange={inputChanged}
                        error={!!errors.positionDesignation}
                        helperText={translateError(errors.positionDesignation)}
                        required
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <KeyboardDatePicker
                        name="endDate"
                        inputVariant="filled"
                        format="DD.MM.YYYY"
                        label={t('employeeInformation:work.entry_date_date_picker_placeholder')}
                        value={startDate}
                        onChange={startDateChanged}
                        error={!!errors.startDate}
                        helperText={translateError(errors.startDate)}
                        required
                        style={{ width: '100%' }}
                        autoOk={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <KeyboardDatePicker
                        name="startDate"
                        inputVariant="filled"
                        format="DD.MM.YYYY"
                        label={t('employeeInformation:work.exit_date_date_picker_placeholder')}
                        value={endDate}
                        onChange={endDateChanged}
                        error={!!errors.endDate}
                        helperText={translateError(errors.endDate)}
                        required
                        autoOk={true}
                        style={{ width: '100%' }}
                    />
                </Grid>
            </Grid>

            <h4 style={headlineStyle}>{t('employeeInformation:termination_type.hint')}</h4>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <RadioGroup aria-label="Kündigungsart" name="terminationType" value={terminationType} onChange={inputChanged}>
                        {terminationRadioButtons}
                    </RadioGroup>
                </Grid>
            </Grid>
        </div>
    )
};
