import { getCookie, removeCookie, setCookie } from '../utils/CookieUtils';

export const SESSION_NAME = '__reflight_session';

export const fetchJWT = async (code) => {
    console.log("Fetching JWT token...");
    const redirectUri = encodeURIComponent(`${window.location.origin}/oauth-callback`);

    const response = await fetch(
        `${window.location.origin}/api/jwtprovider?code=${code}&redirectUri=${redirectUri}`,
        {
            method: 'GET',
        }
    );
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const reponseJson = await response.json();
    const token = reponseJson.token;
    setCookie(SESSION_NAME, token);
    return token;
};

export const logout = () => {
    console.log("Removing session cookie...");
    removeCookie(SESSION_NAME);
    window.location.replace('/');
}

export const isLoggedIn = () => {
    const cookie = getCookie(SESSION_NAME);
    if (!cookie) {
        console.log("Not logged in!");
        return false;
    }

    return fetch(
        `${window.location.origin}/api/loginstatus`,
        {
            method: 'GET',
        }
    ).then((response) => {
        return response.ok;
    });
};
