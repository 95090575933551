
import { isEmpty } from '../../../helpers/object';

const validateEmployerInformation = (values) => {
    const errors = {}
    if (!values.firstName) {
        errors.firstName = 'field_is_required_hint';
    }
    if (!values.familyName) {
        errors.familyName = 'field_is_required_hint';
    }
    if (!values.birthDate) {
        errors.birthDate = 'field_is_required_hint';
    }
    if (!values.startDate) {
        errors.startDate = 'field_is_required_hint';
    }
    if (!values.endDate) {
        errors.endDate = 'field_is_required_hint';
    }
    if (!values.positionDesignation) {
        errors.positionDesignation = 'field_is_required_hint';
    }
    return errors;
}

export const validateActivities = (values) => {
    const activities = [...values.activities];
    const errors = {}

    // identify empty text fields. there should be always at least 1 activity!
    const emptyActivityErrors = activities.reduce((emptyActivityIndices, currentActivity, index) => {
        if (!currentActivity || !currentActivity.length) {
            emptyActivityIndices.push(index);
        }
        return emptyActivityIndices;
    }, []).reduce((obj, emptyIndex) => {
        return Object.assign(obj, {[emptyIndex]: 'field_is_required_hint'})
    }, {});

    if (!isEmpty(emptyActivityErrors)) {
        errors.activities = emptyActivityErrors;
    }

    return errors;
}

export const validations = [
    validateEmployerInformation,
    validateActivities
];
