import React, { useState } from 'react';
import { Button, Paper, Stepper, Step, StepLabel } from '@material-ui/core';
import { Activities, EmployeeInformation, Overview, Partners, Mentoring } from './FormElements';
import { generateRefLetter } from '../../services/ref-letter-service';
import { partners, steps, terminationTypes } from './FormData';
import { Grades } from './FormElements/Grades';
import { validations } from './Validation/validations';
import { FormHeader } from '../RefLightForm/FormHeader';
import { isEmpty } from '../../helpers/object';
import { useTranslation } from 'react-i18next';

const getStepContent = ({
    activeStep,
    values,
    handleInputChange,
    handleDateChange,
    handleActivityInput,
    handleAddActivity,
    handleRemoveActivity,
    errors
}) => {
    switch (activeStep) {
        case 0:
            return (
                <EmployeeInformation
                    firstName={values.firstName}
                    familyName={values.familyName}
                    gender={values.gender}
                    birthDate={values.birthDate}
                    position={values.positionDesignation}
                    startDate={values.startDate}
                    endDate={values.endDate}
                    terminationType={values.terminationType}
                    inputChanged={handleInputChange}
                    dateChanged={(date) => handleDateChange('birthDate', date)}
                    startDateChanged={(date) => handleDateChange('startDate', date)}
                    endDateChanged={(date) => handleDateChange('endDate', date)}
                    errors={errors}
                />
            );
        case 1:
            return (
                <Activities
                    activities={values.activities}
                    inputChanged={handleActivityInput}
                    activityAdded={handleAddActivity}
                    activityRemoved={handleRemoveActivity}
                    errors={errors}
                />
            );
        case 2:
            const mentorLabel = values.gender === 'female' ? 'Mentorin' : 'Mentor'
            return (
                <div>
                    <Grades
                        gradings={
                            {
                                'domain_knowledge': {
                                    name: 'domainKnowledge',
                                    activeGrade: values.domainKnowledge
                                },
                                'overall_performance': {
                                    name: 'overallPerformance',
                                    activeGrade: values.overallPerformance
                                },
                                'overall_picture': {
                                    name: 'overallPicture',
                                    activeGrade: values.overallPicture
                                },
                            }
                        }
                        changed={handleInputChange}
                    />
                    <Mentoring
                        name={`${values.firstName} ${values.familyName}`}
                        mentorLabel={mentorLabel}
                        isMentor={values.isMentor}
                        mentoring={values.mentoring}
                        changed={(event, value) => {
                            handleInputChange(
                                { target: { name: 'mentoring', value: value } }
                            );
                        }}
                        checkboxTicked={(event) => {
                            const checked = event.target.checked;
                            if (!checked) {
                                delete values.mentoring;
                            } else {
                                values.mentoring = 0;
                            }
                            handleInputChange(
                                { target: { name: 'isMentor', value: checked } }
                            );
                        }}
                    />
                </div>
            );
        case 3:
            return (
                <Partners
                    changed={handleInputChange}
                    activePartner={values.partner}
                />
            )
        case 4:
            return (
                <Overview
                    values={values}
                />
            );
        default:
            break;
    }
}

export const RefLightForm = () => {
    const [t, i18next] = useTranslation(['general', 'steps', 'employeeInformation']);
    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(0)
    const [values, setValues] = useState({
        'firstName': '',
        'familyName': '',
        'gender': 'female',
        'positionDesignation': '',
        'birthDate': null,
        'startDate': null,
        'endDate': null,
        'terminationType': `${terminationTypes[0]}`,
        'activities': [''],
        'partner': `${partners[0].fullName}, ${partners[0].title}`,
        'domainKnowledge': 0,
        'overallPerformance': 0,
        'overallPicture': 0,
        'isMentor': false,
    });

    const nextStep = () => {
        let errors = {};
        const validationFn = validations[activeStep];
        if (validationFn) {
            errors = validationFn(values);
        }
        if (isEmpty(errors)) {
            setActiveStep(Math.min(activeStep + 1, steps.length - 1));
        }
        setErrors(errors);
    }

    const prevStep = () => setActiveStep(Math.max(activeStep - 1, 0));

    const handleInputChange = (event) => {
        const { name, value } = event.target
        if (value !== null) {
            setValues({ ...values, [name]: value })
        }
    }

    const handleDateChange = (name, date) => {
        if (date && date.valueOf) {
            setValues({ ...values, [name]: date.valueOf() })
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const submitValues = {
            ...values,
            "terminationType": t('employeeInformation:termination_type.'+values['terminationType']),
            "language": i18next.language,
        }
        console.log(submitValues);
        generateRefLetter(submitValues);
    }

    const handleAddActivity = () => {
        const activities = [...values.activities]
        activities.push('')
        setValues({ ...values, 'activities': activities })
    }

    const handleRemoveActivity = (index) => {
        const activities = [...values.activities]
        if (activities.length > 1) {
            activities.splice(index, 1);
        } else {
            activities[0] = '';
        }
        setValues({ ...values, 'activities': activities })
    }

    const handleActivityInput = (event, index) => {
        const activities = [...values.activities]
        activities[index] = event.target.value;
        setValues({ ...values, 'activities': activities })
    }

    const btnMarginStyle = {
        margin: '8px'
    };

    const btnNoLeftMarginStyle = {
        margin: '8px 8px 8px 0'
    };

    const forwardBtnClasses = activeStep.length === 0 || activeStep === steps.length - 1 ? btnNoLeftMarginStyle : btnMarginStyle;

    return (
        <Paper elevation={3} style={{ padding: '30px' }}>
            <FormHeader  />
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{t('steps:'+label)}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <form style={{ padding: '0 24px' }} onSubmit={handleSubmit}>
                <div style={{ marginBottom: '16px' }}>
                    {getStepContent({
                        activeStep: activeStep,
                        values: values,
                        handleInputChange: handleInputChange,
                        handleDateChange: handleDateChange,
                        handleActivityInput: handleActivityInput,
                        handleAddActivity: handleAddActivity,
                        handleRemoveActivity: handleRemoveActivity,
                        errors: errors
                    })}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {
                        activeStep > 0 ?
                            (
                                <Button classnames="btn-noLeftMargin" variant="contained" onClick={prevStep} style={btnNoLeftMarginStyle}>{t('general:left_navigation_button_title')}</Button>
                            ) : null
                    }
                    {
                        activeStep < steps.length - 1 ?
                            (
                                <Button classnames={forwardBtnClasses} color="primary" variant="contained" onClick={nextStep}>{t('general:right_navigation_button_title')}</Button>
                            ) : null
                    }
                    {
                        activeStep === steps.length - 1 ?
                            (
                                <Button type="submit" color="primary" variant="contained" style={btnMarginStyle}>{t('general:single_navigation_button_title')}</Button>
                            ) : null
                    }
                </div>
            </form>
        </Paper>
    );
};
