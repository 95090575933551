import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import generalEN from './locate/en/general.json';
import generalDE from './locate/de/general.json';
import stepsEN from './locate/en/steps.json';
import stepsDE from './locate/de/steps.json';
import employeeInformationEN from './locate/en/employeeInformation.json';
import employeeInformationDE from './locate/de/employeeInformation.json';
import activitiesEN from './locate/en/activities.json';
import activitiesDE from './locate/de/activities.json';
import gradingEN from './locate/en/grading.json';
import gradingDE from './locate/de/grading.json';
import mentoringEN from './locate/en/mentoring.json';
import mentoringDE from './locate/de/mentoring.json';
import partnersEN from './locate/en/partners.json';
import partnersDE from './locate/de/partners.json';
import overviewEN from './locate/en/overview.json';
import overviewDE from './locate/de/overview.json';

const resources = {
    en: {
        general: generalEN,
        steps: stepsEN,
        employeeInformation: employeeInformationEN,
        activities: activitiesEN,
        grading: gradingEN,
        mentoring: mentoringEN,
        partners: partnersEN,
        overview: overviewEN
    },
    de: {
        general: generalDE,
        steps: stepsDE,
        employeeInformation: employeeInformationDE,
        activities: activitiesDE,
        grading: gradingDE,
        mentoring: mentoringDE,
        partners: partnersDE,
        overview: overviewDE
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        ns: ['general', 'steps', 'employeeInformation', 'activities', 'grading', 'mentoring', 'partners', 'overview'],
        debug: true,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            formatSeparator: ","
        },
        keySeparator: ".",
        react: {
            wait: true,
        }
    });

export default i18n;
